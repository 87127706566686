(function () {
  // 保存 body display style 的值 - 为了配合减轻切换页面左边导航的闪动问题
  var bodyElement = document.body;
  var computedStyle = window.getComputedStyle(bodyElement);
  var bodyDisplayValue = computedStyle.display;
  bodyElement.style.display = 'none';

  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('webkit') === -1 && ua.indexOf('firefox') === -1) {
    // 不支持浏览器的提示
    var tip = document.createElement('div');
    tip.id = 'tip';
    tip.innerHTML =
      '<div style="font-size: 14px; position: fixed;top: 0;left: 0;width: 100%;background-color: #f9e0c7;padding: 0px 20px; z-index: 9999;">' +
      '<div onclick="document.body.removeChild(document.getElementById(\'tip\'))" style="position: fixed; right: 10px; width: 26px; height: 26px; background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjA4IDhMNC4wMDYgNC45MjRsLjkyLS45Mkw4IDcuMDgybDMuMDc2LTMuMDc2LjkyLjkyTDguOTE4IDhsMy4wNzYgMy4wNzYtLjkxOS45Mkw4IDguOTE4bC0zLjA3NiAzLjA3Ni0uOTItLjkxOUw3LjA4MiA4eiIgZmlsbC1vcGFjaXR5PSIuNiIvPjwvc3ZnPg==) center center no-repeat;"></div>' +
      '<span style="line-height: 26px; margin-right: 26px">' +
      '浏览器版本过低，请切换或升级浏览器。建议您使用新版<a style="color: #2575e6;" href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome浏览器</a>以获得更好体验。' +
      '</span></div>';
    document.body.appendChild(tip);
    return;
  }

  var version;
  var envId = null;
  var el = document.querySelector('meta[name=weda]') || document.querySelector('meta[name=portalConfig]');
  if (el) {
    if (el.getAttribute('content')) {
      var content = el.getAttribute('content');
      content.split(/,\s*/).map(function (part) {
        var kv = part.split('=');
        if (kv.length === 2 && kv[0] === 'envId') {
          envId = kv[1];
        }
      });
    }
  } else if (typeof window.WedaPortalConfig === 'object') {
    envId = window.WedaPortalConfig.envId;
  }

  var envName = localStorage.getItem('envName') || 'Default'; // 环境分为 生产：Default，预发：Pre，测试：Test，开发：Dev
  var path = 'https://weda.cloud.tencent.com/workbench/';
  if (envName !== 'Default' && envName !== null) {
    path = 'https://console-1307578329.cos.ap-shanghai.myqcloud.com/workbench/';
  }
  var storedVersion = localStorage.getItem('workbenchVersion');

  // 加载js
  var loadScript = (src, async = true, type = 'text/javascript') => {
    return new Promise((resolve, reject) => {
      try {
        var tag = document.createElement('script');
        var container = document.head || document.body;

        tag.type = type;
        tag.async = async;
        tag.src = src;
        tag.crossOrigin = 'anonymous';

        tag.addEventListener('load', () => {
          resolve({ loaded: true, error: false });
        });

        tag.addEventListener('error', () => {
          reject({
            loaded: false,
            error: true,
            message: `Failed to load script with src ${src}`,
          });
        });

        container.appendChild(tag);
      } catch (error) {
        reject(error);
      }
    });
  };

  // 拉cdn配置
  var loadCdn = function loadCdn() {
    var cdnVersion = '';
    fetch('https://comp-public-replace-1303824488-cos.weda.tencent.com/' + 'release_config/workbench/index.json')
      .then((response) => response.json())
      .then((data) => {
        cdnVersion = data[0].value;
        if (cdnVersion) {
          if (typeof window.WedaPortalConfig === 'object') {
            window.WedaPortalConfig.version = cdnVersion;
          }
          if (!storedVersion) {
            localStorage.setItem('workbenchVersion', cdnVersion);
            return loadScript(path + cdnVersion + '/weda.tools.sdk.js');
          }
        } else {
          if (!storedVersion) {
            return loadScript(path + '1.7.0' + '/weda.tools.sdk.js');
          }
        }
      })
      .catch((error) => {
        if (window.aegis) {
          window.aegis.error({
            msg: error,
            ext1: envId,
            ext3: 'FATAL_ERROR',
          });
        }
      })
      .finally(() => {
        restoreBodyDisplay();
      });
  };

  // 加载sdk文件
  var loadSdk = function loadToolsSdk(version) {
    if (version) {
      loadScript(path + version + '/weda.tools.sdk.js')
        .then(() => {
          if (window.aegis) {
            window.aegis.setConfig({
              version: `workbench-${version}`, // package.json中代码版本
            });
          }
        })
        .catch((e) => {
          // 版本不正确用cdn配置
          loadCdn();
          if (window.aegis) {
            window.aegis.error({
              msg: e,
              ext1: envId,
              ext3: 'FATAL_ERROR',
            });
          }
        })
        .finally(() => {
          restoreBodyDisplay();
        });
    } else {
      // 没有缓存又拉不到七彩石使用cdn缓存
      loadCdn();
    }
  };

  // 安全的json解析
  var safeJsonParse = function safeJsonParse(val) {
    var result;
    if (typeof val === 'string') {
      try {
        result = JSON.parse(val);
      } catch (e) {
        console.error(e);
      }
    }
    return result;
  };

  // 还原 body - 为了配合减轻切换页面左边导航的闪动问题。原理就是延迟浏览器的 First Paint 动作，使得页面切换时导航看上去没变
  var restoreBodyDisplay = function () {
    setTimeout(() => {
      document.body.style.display = bodyDisplayValue;
    }, 0);
  };

  // 加载aegis
  loadScript('https://cdn-go.cn/aegis/aegis-sdk/latest/aegis.min.js')
    .then(() => {
      // 初始化aegis
      var aegis = new window.Aegis({
        id: 'lXHFsBpTzSEkZGieTh', // 项目key
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        pagePerformance: true && {
          firstScreenInfo: true,
        }, // 是否开启页面测速
        webVitals: true, // 是否开启 web vitals 测速
        onError: true, // 当前实例是否需要进行错误监听，获取错误日志
        spa: true,
        api: {
          apiDetail: true,
          // 上报 API 详细信息
          reportRequest: true,
        },
        env: 'production',
        delay: 10,
      });
      window.aegis = aegis;
    })
    .catch((e) => {
      console.error(e);
    });

  // 优先使用本地缓存
  if (storedVersion) {
    try {
      loadSdk(storedVersion);
      if (typeof window.WedaPortalConfig === 'object') {
        window.WedaPortalConfig.version = storedVersion;
      }
    } catch (e) {
      loadSdk();
      if (window.aegis) {
        window.aegis.error({
          msg: e,
          ext1: envId,
          ext3: 'FATAL_ERROR',
        });
      }
    }
  }
  // 获取七彩石配置
  if (typeof envId === 'string') {
    fetch('https://wedaapi.tcbautomation.cn/Graydecision/V1/QueryRainbowConfig', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        EnvId: envId,
        Group: 'workbench',
        EnvType: envName,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response && response.Response && response.Response.Data && response.Response.Data.ConfigValue) {
          var configValue = safeJsonParse(response.Response.Data.ConfigValue);
          if (Array.isArray(configValue) && configValue[0] && configValue[0].kvs && configValue[0].kvs.kvs) {
            var kvs = configValue[0].kvs.kvs;
            var versionObj = kvs.find((item) => item.key === 'version');
            version = versionObj.value;
            if (typeof window.WedaPortalConfig === 'object') {
              window.WedaPortalConfig.version = version;
            }
            if (version) {
              localStorage.setItem('workbenchVersion', version);
            }
            if (!storedVersion) {
              try {
                loadSdk(version);
              } catch (e) {
                loadSdk();
                if (window.aegis) {
                  window.aegis.error({
                    msg: e,
                    ext1: envId,
                    ext3: 'FATAL_ERROR',
                  });
                }
              }
            }
          }
        } else {
          throw new Error(response);
        }
      })
      .catch((error) => {
        loadSdk();
        if (window.aegis) {
          window.aegis.error({
            msg: error,
            ext1: envId,
            ext3: 'FATAL_ERROR',
          });
        }
      });
  } else if (!storedVersion) {
    loadSdk();
  }
})();
